// 获取列表
const getListURL = `${API_CONFIG.baseURL}serverEstateAction!list.action`
// 导出列表
const exportListURL = `${API_CONFIG.shopBaseURL}orderSettle/exportOrderSettle`
// 获取组织列表
const getOrgListURL = `${API_CONFIG.butlerBaseURL}orgInfo/searchOrgByKeyword`
// 批量操作
const batchURL = `${API_CONFIG.butlerBaseURL}estates/updateEstatesPaymentPaidInfoBatch`
// 获取详情
const getEstateDetailURL = `${API_CONFIG.baseURL}serverEstateAction!estateDetail.action`
// 编辑保存
const updateEstatesPaymentPaidInfoURL = `${API_CONFIG.butlerBaseURL}estates/updateEstatesPaymentPaidInfo`
// 编辑金额保存
const updateEstatesPaymentTotalPriceURL = `${API_CONFIG.butlerBaseURL}estates/updateEstatesPaymentTotalPrice`
// 删除
const deleteEstatesPaymentURL = `${API_CONFIG.butlerBaseURL}estates/deleteEstatesPayment`
// 核销
const writeOffEstatesPaymentURL = `${API_CONFIG.butlerBaseURL}estates/writeOffEstatesPayment`
// 导入地址
const uploadUrl = `${API_CONFIG.butlerBaseURL}serverEstates/importEstates`
// 导出账单
const uploadBillUrl = `${API_CONFIG.butlerBaseURL}property/exportPropertyPay`

export {
  getListURL,
  exportListURL,
  getOrgListURL,
  batchURL,
  getEstateDetailURL,
  updateEstatesPaymentPaidInfoURL,
  updateEstatesPaymentTotalPriceURL,
  deleteEstatesPaymentURL,
  writeOffEstatesPaymentURL,
  uploadUrl,
  uploadBillUrl
}
