<template>
  <div class="billDetailsList-wrapper">
    <!-- <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
      :selectable="selectable"
    > -->
    <list
      ref="list"
      :exportPermission="exportPermission"
      :searchUrl="searchUrl"
      :multiExport="multiExport"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
      :selectable="selectable"
    >
      <template #headerSlot>
        <v-button text="批量导入" permission="batch_import" @click="goPageImport"></v-button>
        <!-- <v-button text="导出账单" permission="export_property" @click="getExportBill"></v-button> -->
      </template>
      <template #searchSlot>
        <v-input label="付款单号" v-model="searchParams.payNo"></v-input>
        <v-input label="付款联系人" v-model="searchParams.userName"></v-input>
        <v-select label="收费项目" v-model="searchParams.item" :options="itemOps"></v-select>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-input label="苑" v-model="searchParams.address"></v-input>
        <v-input label="幢" v-model="searchParams.buildNum"></v-input>
        <v-input label="单元" v-model="searchParams.unitNum"></v-input>
        <v-input label="室" v-model="searchParams.roomNum"></v-input>
        <v-select label="缴费状态" v-model="searchParams.payStatus" :options="payStatusOps"></v-select>
        <v-datepicker label="缴费时间" :startTime.sync="searchParams.startDate" :endTime.sync="searchParams.endDate" type="rangedatetimer"></v-datepicker>
        <v-select label="缴费途径" v-model="searchParams.optSource" :options="optSourceOps"></v-select>
        <v-select label="付款方式" v-model="searchParams.payType" :options="payTypeOps"></v-select>
        <v-datepicker
          type="rangemonth"
          label="计费周期"
          placeholder="请选择计费年月"
          format="YYYY-MM"
          :startTime.sync="searchParams.billStartTime"
          :endTime.sync="searchParams.billEndTime">
        </v-datepicker>
      </template>
      <template #operateSlot="scope">
        <a v-if="scope.row.payStatus === 1">
          <v-button text="查看" type="text" permission="search" @click="edit(scope.row)"></v-button>
          <a v-if="scope.row.writeOff === 0 && scope.row.optSource !== '小邑'">
            <el-dropdown trigger="click" @command="moreHandle" v-if="retry || deleter">
              <span class="el-dropdown-link">
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="retry" :command="{ row: scope.row, type: 'writeoff' }">
                  <span>核销</span>
                </el-dropdown-item>
                <el-dropdown-item v-if="deleter" :command="{ row: scope.row, type: 'delete' }">
                  <span>删除</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </a>
          <a v-else>
            <v-button v-if="scope.row.writeOff === 0" text="核销" type="text" permission="retry" @click="edit(scope.row)"></v-button>
            <v-button v-if="scope.row.optSource !== '小邑'" text="删除" type="text" permission="delete" @click="deletePay(scope.row)"></v-button>
          </a>
        </a>
        <a v-else>
          <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
          <el-dropdown v-if="!scope.row.payNo" trigger="click" @command="moreHandle">
              <span class="el-dropdown-link">
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="updateMoney" :command="{ row: scope.row, type: 'updateMoney' }">
                  <span>修改账单金额</span>
                </el-dropdown-item>
                <el-dropdown-item v-if="deleter" :command="{ row: scope.row, type: 'delete' }">
                  <span>删除</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
        </a>
      </template>
      <template #batchSlot="scope">
        <v-button text="批量缴费" :disabled="scope.selectedData.ids.length === 0" permission="batch_estates" @click="batch(scope.selectedData)"></v-button>
      </template>
    </list>
    <el-dialog
      title="提示"
      :visible.sync="operaDialog"
      width="700px"
      class="dialog"
      @close="closeDialog">
      <el-form ref="form" :model="form" :rules="rules" label-width="140px" label-suffix="：" size="medium">
      <el-row>
        <el-col :span="12">
          <el-form-item label="缴费时间" prop="paidTime" :rules="[{ required: true, message: '请填写缴费时间', trigger: 'change' }]">
            <v-datepicker v-model="form.paidTime" :width="180" type="datetime"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="付款流水号"  prop="payNum">
            <v-input v-model="form.payNum"></v-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="发票抬头" prop="invoiceTitle">
            <v-input v-model="form.invoiceTitle"></v-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="缴费人" prop="payUserName">
            <v-input v-model="form.payUserName"></v-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="联系电话" prop="payUserPhone" :rules="[{ validator: validatePhone, trigger: 'blur' }]">
            <v-input v-model="form.payUserPhone"></v-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系地址" prop="payUserAddress">
            <v-input v-model="form.payUserAddress"></v-input>
          </el-form-item>
        </el-col>
      </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="operaDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitOpera">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  batchURL,
  uploadBillUrl,
  deleteEstatesPaymentURL,
  writeOffEstatesPaymentURL
} from './api'
import {
  itemOps,
  payStatusOps,
  optSourceOps,
  payTypeOps,
  payTypeMap
} from './map'
import { Dropdown, DropdownMenu, DropdownItem, Row, Col } from 'element-ui'
import { mobileRegular } from 'common/regular'
import { communityParams } from 'common/select2Params'

export default {
  name: 'BillDetailsList',
  components: {
    elDropdown: Dropdown,
    elDropdownMenu: DropdownMenu,
    elDropdownItem: DropdownItem,
    elRow: Row,
    elCol: Col
  },
  data () {
    let _this = this
    return {
      itemOps,
      payStatusOps,
      optSourceOps,
      payTypeOps,
      communityParams,
      searchUrl: getListURL,
      // exportUrl: exportListURL,
      // exportPermission: true,
      multiExport: [
        {
          text: '导出',
          url: exportListURL,
          permission: 'export'
        },
        {
          text: '导出账单',
          url: uploadBillUrl,
          permission: 'export_property',
          before: this.exportLimit
        }
      ],
      searchParams: {
        payNo: '',
        userName: '',
        item: undefined,
        communityId: '',
        address: '',
        buildNum: '',
        unitNum: '',
        roomNum: '',
        payStatus: undefined,
        startDate: '',
        endDate: '',
        optSource: undefined,
        payType: undefined,
        billStartTime: '',
        billEndTime: ''
      },
      headers: [
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'ownerRoomName',
          label: '业主房号'
        },
        {
          prop: 'itemAddress',
          label: '收费地址'
        },
        {
          prop: 'item',
          label: '收费项目'
        },
        {
          prop: 'date0',
          label: '出账月份',
          formatter (row) {
            return `${row.billYear}年${row.billMonth}月`
          }
        },
        {
          prop: 'date1',
          label: '计费周期',
          formatter (row) {
            return `${row.billStartTime}~${row.billEndTime}`
          }
        },
        {
          prop: 'count',
          label: '面积/用量/数量',
          align: 'right'
        },
        {
          prop: 'totalPrice',
          label: '应缴金额',
          align: 'right'
        },
        {
          prop: 'userNameText',
          label: '付款联系人',
          formatter (row) {
            let userName = row.userName
            let userPhone = row.userPhone
            let h = _this.$createElement
            return h('div', [
              h('span', {
                domProps: {
                  innerText: userName
                }
              }),
              h('br'),
              h('span', {
                domProps: {
                  innerText: userPhone
                }
              })
            ])
          }
        },
        {
          prop: 'payMoney',
          label: '实付金额',
          align: 'right'
        },
        {
          prop: 'payNo',
          label: '付款单号'
        },
        {
          prop: 'payTime',
          label: '缴费时间'
        },
        {
          prop: 'statusText',
          label: '缴费状态',
          formatter (row) {
            let text = !row.status ? '已删除' : row.payStatus === 0 ? '待缴费' : '已缴费'
            let color = row.status && row.payStatus === 0 ? 'red' : '#333'
            return _this.$createElement('a', {
              domProps: {
                innerText: text
              },
              style: {
                color
              }
            })
          }
        },
        {
          prop: 'optSource',
          label: '缴费途径'
        },
        {
          prop: 'payTypeText',
          label: '付款方式',
          formatter (row) {
            return payTypeMap[row.payType]
          }
        },
        {
          prop: 'writeOffText',
          label: '财务核销',
          formatter (row) {
            return row.writeOff ? '已核销' : '未核销'
          }
        },
        {
          prop: 'updateUserName',
          label: '最近操作人'
        },
        {
          prop: 'updateTime',
          label: '最近操作时间'
        }
      ],
      postHeader: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      },
      operaDialog: false,
      rules: {},
      form: {
        paidTime: '',
        payNum: '',
        invoiceTitle: '',
        payUserPhone: '',
        payUserName: '',
        payUserAddress: ''
      },
      ids: [],
      communityId: ''
    }
  },
  computed: {
    retry () { return this.$store.getters.getPermission('retry') },
    deleter () { return this.$store.getters.getPermission('delete') },
    updateMoney () { return this.$store.getters.getPermission('update_money') },
    exportPermission () {
      // 当导出存在多个且权限不同时,为防止出现点击导出下拉选项为空的情况
      let exportPermission = false
      this.multiExport.forEach(v => {
        if (v.permission && this.$store.getters.getPermission(v.permission)) {
          exportPermission = true
        }
      })
      return exportPermission
    }
  },
  methods: {
    exportLimit () {
      if (!this.searchParams.communityId) {
        this.$message('请先选择所属项目')
        return false
      }
      return true
    },
    validatePhone (rule, value, callback) {
      let regExp = mobileRegular
      if (value && value.length > 0 && !regExp.test(value)) {
        callback(new Error('手机号码格式不正确'))
      } else {
        callback()
      }
    },
    getExportBill () {
    },
    selectable (row, index) {
      return row.payStatus === 0
    },
    batch (selected) {
      this.communityId = ''
      if (selected.data && selected.data.length > 0) {
        this.communityId = selected.data[0].communityId
      }
      this.ids = selected.ids
      this.operaDialog = true
    },
    goPageImport () {
      this.$router.push({
        name: 'billDetailsImport'
      })
    },
    edit (row, updateMoney) {
      this.$router.push({
        name: 'billDetailsForm',
        query: {
          id: row.id,
          communityId: row.communityId,
          updateMoney: updateMoney || 0
        }
      })
    },
    moreHandle (val) {
      if (val.type === 'writeoff') {
        this.writeoff(val.row)
      } else if (val.type === 'delete') {
        this.deletePay(val.row)
      } else if (val.type === 'updateMoney') {
        this.edit(val.row, 1)
      }
    },
    async deletePay (row) {
      let data = {
        id: row.id,
        communityId: row.communityId
      }
      let isOk = await this.$confirm('确认删除？')
      isOk && this.$axios.post(deleteEstatesPaymentURL, this.$qs.stringify(data), this.postHeader).then(res => {
        if (res.status == 100) { // eslint-disable-line
          this.$refs.list.searchData()
          this.$message({
            type: 'success',
            message: '删除成功',
            center: true
          })
        }
      })
    },
    async writeoff (row) {
      let data = {
        id: row.id,
        communityId: row.communityId
      }
      let isOk = await this.$confirm('确认核销？')
      isOk && this.$axios.post(writeOffEstatesPaymentURL, this.$qs.stringify(data), this.postHeader).then(res => {
        if (res.status == 100) { // eslint-disable-line
          this.$refs.list.searchData()
          this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    closeDialog () {
      this.$refs.form.resetFields()
    },
    submitOpera () {
      this.$refs.form.validate((result, object) => {
        if (result) {
          let data = {
            idList: this.ids.join(','),
            communityId: this.communityId,
            ...this.form
          }
          this.$axios.post(batchURL, this.$qs.stringify(data), this.postHeader).then(res => {
            this.operaDialog = false
            if (res.status == 100) { // eslint-disable-line
              this.$refs.list.searchData()
              this.$message({
                type: 'success',
                message: '操作成功',
                center: true
              })
            }
          })
        }
      })
    }
  }
}
</script>
<style>
.mx-datepicker-popup {
  z-index: 5000;
}
</style>
