import { generateMapByOpts } from 'common/utils'
// 收费项目
const itemOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '物业服务费',
    value: '物业服务费'
  }, {
    text: '代收水费',
    value: '代收水费'
  }, {
    text: '代收电费',
    value: '代收电费'
  }, {
    text: '车位服务费',
    value: '车位服务费'
  }, {
    text: '公共能耗费',
    value: '公共能耗费'
  }, {
    text: '库房服务费',
    value: '库房服务费'
  }, {
    text: '热水费',
    value: '热水费'
  }, {
    text: '采暖费',
    value: '采暖费'
  }, {
    text: '燃气费',
    value: '燃气费'
  }, {
    text: '中水费',
    value: '中水费'
  }, {
    text: '代收煤气费',
    value: '代收煤气费'
  }, {
    text: '保证金',
    value: '保证金'
  }, {
    text: '房租',
    value: '房租'
  }, {
    text: '电梯费',
    value: '电梯费'
  }, {
    text: '空调费',
    value: '空调费'
  }, {
    text: '车库租金',
    value: '车库租金'
  }, {
    text: '其它',
    value: '其它'
  }
]
// 缴费状态
const payStatusOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '已缴费',
    value: 1
  }, {
    text: '待缴费',
    value: 0
  }, {
    text: '已删除',
    value: 2
  }
]

const payStatusMap = generateMapByOpts(payStatusOps)
// 缴费途径
const optSourceOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '业主端App',
    value: 1
  }, {
    text: '新视窗',
    value: 2
  }, {
    text: '支付宝',
    value: 3
  }, {
    text: '后台录入',
    value: 4
  }, {
    text: '物管端App',
    value: 5
  }, {
    text: '微信小程序',
    value: 7
  }
]
const optSourceMap = generateMapByOpts(optSourceOps)
// 付款方式
const payTypeOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '支付宝',
    value: 1
  }, {
    text: '微信',
    value: 12
  }, {
    text: '余额支付',
    value: 5
  }, {
    text: '零额支付',
    value: 99
  }, {
    text: '线下支付',
    value: 0
  }
]
const payTypeMap = generateMapByOpts(payTypeOps)
// 付款方式 编辑
const payTypeOps2 = [
  {
    text: '线下',
    value: 0
  }, {
    text: '支付宝',
    value: 1
  }, {
    text: '银联',
    value: 2
  }, {
    text: '微信',
    value: 3
  }
]
export {
  itemOps,
  payStatusOps,
  payStatusMap,
  optSourceOps,
  optSourceMap,
  payTypeOps,
  payTypeMap,
  payTypeOps2
}
